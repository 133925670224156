(function($) {

    'use strict';

    // Variables //

    // Navigation
    var mainNav = $('.site-navigation'),
        toggler = $('.nav-toggler');

    function toggleNavigation(button, navigation, openClass) {
        if (!openClass)
            openClass = 'open';

        button.toggleClass(openClass);
        navigation.toggleClass(openClass);
    }

    toggler.on('click', function(e) {
        e.preventDefault();
        toggleNavigation(toggler, mainNav);
    });

    // Form
    var form = $('#order-form'),
        submit = $('#submit-order'),
        month = $('#expiration_month'),
        year = $('#expiration_year'),
        date = $('#expirationDate'),
        products = $('#productId'),
        shipping = $('#shippingId');

    month.on('change', function() {
        var m = $(this).val(),
            y = year.val();

        date.val(m + y);
    });

    year.on('change', function() {
        var m = month.val(),
            y = $(this).val();

        date.val(m + y);
    });

    products.on('change', function() {
        var s = $(this).find(':selected'),
            v = s.val(),
            p = parseFloat(s.attr('data-product-price')),
            shipping = parseFloat($('#shipp').html());

        $('#sub').html(p.toFixed(2));
        $('#total').html((p + shipping).toFixed(2));
    });

    shipping.on('change', function() {
        var s = products.find(':selected'),
            v = s.val(),
            p = parseFloat(s.attr('data-product-price')),
            shipping;

       if ($(this).is(':checked')) {
           shipping = parseFloat($(this).attr('data-shipping-price'))
       } else {
            shipping = 0.00;
       }

        $('#shipp').html(shipping.toFixed(2));
        $('#total').html((p + shipping).toFixed(2));
    });

    if (form) {
        var billing_s = $('#billing-s');
        var states, statesHtml;

        $('.shipp-wrapp').before($('#summary'));

        $.ajax({
            type: 'get',
            url: 'application/config/states.json',
            success: function(d) {
                states = d;

                $.each(states, function(i) {
                    statesHtml += '<option value="' + states[i].code + '">' + states[i].name + '</option>';
                });

                $('#billingState, #shippingState').append(statesHtml);
            }
        });

        $('#billingSameAsShipping').on('click', function() {
            if ($(this).is(':checked')) {
                billing_s.slideDown();
            } else {
                billing_s.slideUp();
            }
        });

        submit.on('click', function(e) {
            e.preventDefault();

            var data = form.serialize();
            var action = form.attr('action');

            if ($('#shippingId').is(':checked')) {

            } else {
                data += '&shippingId=5';
            }

            if ($('#terms').is(':checked')) {
                $.ajax({
                    type: 'post',
                    data: data,
                    url: action,
                    success: function(d) {
                        var w = $('.form-overlay'), msg;

                        if (d === '100') {
                            msg = 'Everything is good. You\'ll be redirected shortly.'
                        } else {
                            msg = d;
                        }

                        w.fadeIn(500, function() {
                            w.find('.message').html(msg);
                            setTimeout(function() {
                                if (d === '100') {
                                    window.location.href = 'thankyou?order=completed';
                                } else {
                                    w.fadeOut(500, function() {
                                        w.find('.message').html('');
                                    })
                                }
                            }, 3000)
                        })
                    }
                })
            }
        })
    }

    var labelToggle = $('.label-show'),
        labelOverlays = $('.label-over');

    labelToggle.on('click', function(e) {
        e.preventDefault();
        var t = $($(this).attr('href'));
        t.fadeIn(500, function() {
            t.on('click', function() {
                t.fadeOut();
            })
        })
    })

})(jQuery);